import { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/sitelogo.png";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../core/form-components/Dropdown";
import Paragraph from "../core/typography/Paragraph";
import { FaBell, FaUser } from "react-icons/fa6";
import Modal from "../core/Modal";
import { TbAlertTriangleFilled } from "react-icons/tb";
import Button from "../core/form-components/Button";
import { IoPower } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshTokenSelector,
  setRefreshToken,
  setToken,
  tokenSelector,
  userSelector,
  userTypeSelector,
} from "../../redux/slices/userSlice";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { LogoutApi } from "../../service/authService";
import { GetNotificationList } from "../../service/dashboardService";
import NotificationItem from "../NotificationItem";

function Header() {
  const dispatch = useDispatch();
  const accessToken = useSelector(tokenSelector);
  const refreshToken = useSelector(refreshTokenSelector);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const userData = useSelector(userSelector);
  const userType = useSelector(userTypeSelector);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [notificationData, setNotificationData] = useState([]);

  const dropdownItems = [
    {
      icon: FaUser,
      LinkName: "Profile",
      Link: "",
      action: null,
    },
    {
      icon: IoPower,
      LinkName: "Logout",
      Link: "",
      action: () => setOpen(true),
    },
  ];

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const res = await GetNotificationList();
        if (res?.status === 200) {
          setNotificationData(res?.data?.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchNotification();
  }, []);

  const handleLogout = async () => {
    dispatch(showLoader());
    const response = await LogoutApi({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
    if (response?.data?.status === 200 && response?.data?.success) {
      dispatch(setToken(null));
      dispatch(setRefreshToken(null));
      navigate("/login");
    }
    dispatch(hideLoader());
  };

  const toggleDropdown = (dropdown) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="md:col-span-4 sm:col-span-5">
        <div className="block">
          <Link to="/dashboard">
            <img src={Logo} alt="logo" width="117px" height="46px" />
          </Link>
        </div>
      </div>
      <div className="md:col-span-8 sm:col-span-7">
        <div className="flex items-center justify-end gap-3 ">
          <div className="lg:flex hidden items-center justify-end gap-3 ">
            {/* Notification Button */}
            <div className="relative" ref={dropdownRef}>
              <button
                className={`md:w-[44px] md:h-[44px] sm:w-8 sm:h-8 mr-2 rounded-full bg-[#F7F7FC] overflow-hidden flex items-center justify-center relative`}
                onClick={() => {
                  toggleDropdown("notification");
                }}
              >
                <FaBell fontSize={"20px"} />
                <span className="absolute w-2 h-2 rounded-full bg-[#CB0303] border border-white top-[10px] right-[10px]"></span>
              </button>
              {isOpen === "notification" && (
                <div className="origin-top-right p-5 absolute -right-2 mt-5 w-[500px] rounded-lg shadow-cardShadow bg-white z-10 min-w-[355px]">
                  <div className="z-0 flex items-center justify-between gap-3 pb-3 relative before:border-transparent before:border-r-[12px] before:border-l-[12px] before:border-b-[16px] before:border-b-white before:absolute before:-top-8 before:right-1.5 before:-z-[1]">
                    <Paragraph text20>
                      Notifications (
                      {notificationData?.length ? notificationData?.length : 0})
                    </Paragraph>
                  </div>
                  <div
                    className="max-h-[252px] overflow-y-auto"
                    id={"scrollableDiv"}
                  >
                    {notificationData?.length == 0 ? (
                      <div>no notification</div>
                    ) : (
                      <div>
                        {notificationData?.map((item) => (
                          <Link
                            to={`/account/${item?.notifications?.information?.invoice_id}`}
                            key={item?.id}
                          >
                            <NotificationItem
                              title={item.notifications.title}
                              description={item.notifications.description}
                            />
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Dropdown
            className={"flex items-center justify-center"}
            dropdownItems={dropdownItems}
            withChevron
          >
            <div
              className={
                "md:w-[44px] md:h-[44px] sm:w-8 sm:h-8 mr-2 rounded-full bg-[#F7F7FC] overflow-hidden flex items-center justify-center"
              }
            >
              {/* {userProfile ? (
                <img
                  src={userProfile}
                  alt="userprofile"
                  width={44}
                  height={44}
                  className={""}
                />
              ) : ( */}
              <FaUser fontSize={"20px"} />
              {/* )} */}
            </div>
            <Paragraph text14Semibold className={"text-dark-grey mr-[2px]"}>
              {userData?.username} ({userType?.name})
            </Paragraph>
          </Dropdown>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)} className={``}>
        <div className="p-[30px]">
          <span className="flex items-center justify-center mb-6">
            <TbAlertTriangleFilled
              fontSize={"50px"}
              className="text-light-red"
            />
          </span>
          <Paragraph
            className={`mb-30 text-center text-[20px] leading-[26px] font-medium`}
          >
            Are you sure you want to logout?
          </Paragraph>
          <div className="flex items-center justify-center gap-3">
            <Button onClick={() => handleLogout()} primary className={`w-full`}>
              Yes
            </Button>
            <Button
              secondary
              onClick={() => setOpen(false)}
              className={`w-full`}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
