import React, { useEffect, useState } from "react";
import Paragraph from "../../components/core/typography/Paragraph";
import Breadcrumbs from "../../components/core/Breadcrumbs";
import Button from "../../components/core/form-components/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiSendPlaneFill } from "react-icons/ri";
import { BiLogoWhatsapp, BiSolidPhoneCall } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { IoIosMail } from "react-icons/io";
import Modal from "../../components/core/Modal";
import FormLabel from "../../components/core/typography/FormLabel";
import SelectType from "../../components/core/form-components/SelectType";
import {
  InvoiceDetail,
  FollowUpHistory,
  SendFollowUp,
  SendUserMsg,
  GetAllUsers,
  SendEscalateInvoice,
} from "../../service/dashboardService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { Followup_Platform } from "../../constants/constants";

const renderJsonList = (obj, level = 0) => {
  return (
    <ul className={`${level ? "pl-7" : ""} text-[16px] list-none`}>
      {Object.keys(obj).map((key) => (
        <li key={key}>
          {key}:{" "}
          {typeof obj[key] === "object"
            ? renderJsonList(obj[key], level + 1)
            : obj[key]}
        </li>
      ))}
    </ul>
  );
};

function ClientAccount() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModalEscalate, setOpenEscalate] = useState(false);
  const [openModalAI, setOpenAI] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState();
  const [followupHistory, setFollowupHistory] = useState();
  const [comment, setComment] = useState("");
  const [selectPlateform, setSelectPlateform] = useState("");
  const [stakeholderList, setStakeholderList] = useState([]);
  const [stakeholder, setStakeholder] = useState();
  const [airesponce, setAiResponce] = useState({
    question: "",
    answer: "",
  });
  const [aiMessage, setaiMessage] = useState("");

  useEffect(() => {
    fetchInvoiceDetail(Number(params.id));
    fetchFollowupHistory(Number(params.id));
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, []);
  useEffect(() => {
    if (openModalAI) sendAiResponce();
  }, [openModalAI]);

  const fetchInvoiceDetail = async (id) => {
    try {
      dispatch(showLoader());
      const data = await InvoiceDetail(id);
      if (!data || data === undefined) {
        dispatch(hideLoader());
        navigate("/dashboard");
        return;
      }
      setInvoiceDetail(data.data?.data);
    } catch (error) {
      console.error("Error fetching invoice detail:", error);
      navigate("/dashboard");
    } finally {
      dispatch(hideLoader());
    }
  };

  const fetchFollowupHistory = async (id) => {
    try {
      dispatch(showLoader());
      const data = await FollowUpHistory(id);
      if (!data || data === undefined) {
        dispatch(hideLoader());
        navigate("/dashboard");
        return;
      }
      setFollowupHistory(data.data?.data?.reverse());
    } catch (error) {
      console.error("Error fetching follow-up history:", error);
      navigate("/dashboard");
    } finally {
      dispatch(hideLoader());
    }
  };

  const sendAiResponce = async () => {
    try {
      dispatch(showLoader());
      const response = await SendUserMsg(
        `?client_id=${invoiceDetail?.customer_id}`,
        {
          user_input: aiMessage,
        }
      );
      if (response.status === 200) {
        setAiResponce({ question: aiMessage, answer: response.data.data });
        setaiMessage("");
        formatAiResponse(response.data.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoader());
    }
  };

  const sendComment = async () => {
    try {
      dispatch(showLoader());
      const data = {
        followup_platform: selectPlateform,
        comments: comment,
      };
      const response = await SendFollowUp(params.id, data);
      await fetchFollowupHistory(Number(params.id));
      setComment("");
    } catch (error) {
      console.error("Error sending comment:", error);
    } finally {
      setSelectPlateform("");
      dispatch(hideLoader());
    }
  };

  const fetchAllUsers = async () => {
    try {
      dispatch(showLoader());
      let queryString = `?search=${""}`;
      const res = await GetAllUsers(queryString);
      const transformedData = res?.data?.data.map((user) => ({
        label: `${user.username} - ${user.user_type.name}`,
        value: user.id,
      }));
      setStakeholderList(transformedData);
    } catch (error) {
      console.error("Error fetching invoice detail:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const sendEscalateInvoice = async () => {
    try {
      dispatch(showLoader());
      const response = await SendEscalateInvoice(params.id, {
        user: stakeholder,
      });
    } catch (error) {
      console.error("Error sending comment:", error);
    } finally {
      setOpenEscalate(false);
      dispatch(hideLoader());
    }
  };

  const formatAiResponse = (data) => {
    const jsonString = data.split("Response: ")[1].trim();
    const jsonObject = JSON.parse(jsonString);
    setAiResponce((prev) => ({
      question: prev.question,
      answer: jsonObject,
    }));
    return jsonObject;
  };

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex items-center justify-between pb-3">
            <div className="block">
              <Breadcrumbs id={invoiceDetail?.invoice_number || ""} />
              <Paragraph text28>
                {invoiceDetail?.invoice_number || "-"} -{" "}
                {invoiceDetail?.customer_name || "-"}
              </Paragraph>
            </div>
            <Button primary onClick={(e) => setOpenEscalate(true)}>
              Escalate Account
            </Button>
            <Modal
              open={openModalEscalate}
              onClose={(e) => setOpenEscalate(false)}
              header
              headingText="Escalate Account"
            >
              <div className="px-6 pb-6">
                <div className="grid grid-cols-12">
                  <div className="col-span-12 mb-30">
                    <Paragraph text14 className={`text-dark-grey mb-3`}>
                      AR team can escalate the Client Account to other
                      Stakeholders
                    </Paragraph>
                    <FormLabel>Select Stakeholder</FormLabel>
                    <SelectType
                      options={stakeholderList ? stakeholderList : []}
                      placeHolder="Stakeholder"
                      onChange={(e) => setStakeholder(e.value)}
                      fullWidth
                    />
                  </div>
                  <div className="col-span-12">
                    <div className="flex items-center justify-center gap-4">
                      <Button
                        secondary
                        onClick={() => setOpenEscalate(false)}
                        className={`w-1/3`}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={`w-2/3`}
                        primary={stakeholder}
                        disabled={!stakeholder}
                        onClick={sendEscalateInvoice}
                      >
                        Escalate Account
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-8">
          <div className="h-full bg-white rounded-xl xl:py-30 py-4">
            <div className="xl:pb-4 xl:px-30 pb-3 px-4">
              <Paragraph text20>Account Details</Paragraph>
            </div>
            <div className="xl:px-30 px-4 overflow-y-auto xl:h-[calc(100vh-314px)] h-[calc(100vh-260px)]">
              <div className="columns-2 xl:gap-4 gap-3">
                <div className="break-inside-avoid border border-extra-light-blue rounded-lg overflow-hidden xl:mb-4 mb-3">
                  <Paragraph
                    text14Semibold
                    className="py-[14px] px-3 bg-light-grey-bg"
                  >
                    Client Information
                  </Paragraph>
                  <ul className="xl:p-4 p-3">
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Client Name
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.customer_name || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Email Address
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.customer_email || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1">
                      <Paragraph text14 className={`text-site-black`}>
                        Phone Address
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.customer_phone || "-"}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
                <div className="break-inside-avoid border border-extra-light-blue rounded-lg overflow-hidden xl:mb-4 mb-3">
                  <Paragraph
                    text14Semibold
                    className="py-[14px] px-3 bg-light-grey-bg"
                  >
                    Payment Information
                  </Paragraph>
                  <ul className="xl:p-4 p-3">
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Total Receivable
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {`${invoiceDetail?.currency_type} $${invoiceDetail?.total_recivable || "-"}`}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Amount Overdue
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {`${invoiceDetail?.currency_type} $${invoiceDetail?.amount_overdue || "-"}`}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Upcoming Payment
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {`${invoiceDetail?.currency_type} $${invoiceDetail?.total_recivable || "-"}`}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Last Due Date
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.due_date_plus_7 || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Upcoming Payment Date
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.due_date_plus_7 || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Last Paid Amount
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.last_paid_amount && "$"}
                        {invoiceDetail?.last_paid_amount || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1">
                      <Paragraph text14 className={`text-site-black`}>
                        Last Paid Date
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.last_paid_date || "-"}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
                <div className="break-inside-avoid border border-extra-light-blue rounded-lg overflow-hidden xl:mb-4 mb-3">
                  <Paragraph
                    text14Semibold
                    className="py-[14px] px-3 bg-light-grey-bg"
                  >
                    Overdue Summary
                  </Paragraph>
                  <ul className="xl:p-4 p-3">
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Age of Account
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.overdue_status || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1">
                      <Paragraph text14 className={`text-site-black`}>
                        Priority
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.priority || "-"}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
                <div className="break-inside-avoid border border-extra-light-blue rounded-lg overflow-hidden xl:mb-4 mb-3">
                  <Paragraph
                    text14Semibold
                    className="py-[14px] px-3 bg-light-grey-bg"
                  >
                    Project Information
                  </Paragraph>
                  <ul className="xl:p-4 p-3">
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Project Name
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.project_name || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1">
                      <Paragraph text14 className={`text-site-black`}>
                        Milestones
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.milestone_name || "-"}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
                <div className="break-inside-avoid border border-extra-light-blue rounded-lg overflow-hidden xl:mb-4 mb-3">
                  <Paragraph
                    text14Semibold
                    className="py-[14px] px-3 bg-light-grey-bg"
                  >
                    Invoice Information
                  </Paragraph>
                  <ul className="xl:p-4 p-3">
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Invoice Number
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.invoice_number || "-"}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1 mb-3">
                      <Paragraph text14 className={`text-site-black`}>
                        Invoice Amount
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {`${invoiceDetail?.currency_type} $${invoiceDetail?.invoice_amount || "-"}`}
                      </Paragraph>
                    </li>
                    <li className="flex items-center justify-between gap-1">
                      <Paragraph text14 className={`text-site-black`}>
                        Invoice Date
                      </Paragraph>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {invoiceDetail?.invoice_date || "-"}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="bg-white rounded-xl overflow-hidden top-[100px]">
            <div className="flex xl:items-center items-start justify-between xl:flex-row flex-col gap-1 xl:pt-30 pt-4 xl:pb-4 pb-3 xl:px-30 px-4">
              <Paragraph text20>Follow up History</Paragraph>
              <Link
                onClick={(e) => setOpenAI(true)}
                className="text-[14px] leading-[18px] font-normal flex items-center xxl:gap-2 gap-1"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.31873 8.19742L7.06873 15.2174C7.05538 15.2564 7.05147 15.2981 7.05734 15.3389C7.0632 15.3797 7.07866 15.4185 7.10245 15.4522C7.12625 15.4859 7.15769 15.5134 7.1942 15.5326C7.23071 15.5517 7.27125 15.562 7.31248 15.5624H7.94248C7.99871 15.5623 8.05338 15.5439 8.09832 15.5101C8.14327 15.4763 8.17608 15.4289 8.19185 15.3749L8.54435 14.2499H11.625L11.5819 14.1805L11.9681 15.3749C11.9839 15.4289 12.0167 15.4763 12.0616 15.5101C12.1066 15.5439 12.1612 15.5623 12.2175 15.5624H12.8475C12.8887 15.5627 12.9295 15.5531 12.9663 15.5345C13.0032 15.516 13.0351 15.4889 13.0594 15.4556C13.0838 15.4223 13.0999 15.3836 13.1064 15.3429C13.1129 15.3021 13.1096 15.2604 13.0969 15.2212L10.8469 8.20117C10.8308 8.14735 10.7979 8.1001 10.753 8.06635C10.7082 8.0326 10.6536 8.01414 10.5975 8.01367H9.56248C9.50769 8.01479 9.45465 8.03314 9.41089 8.06613C9.36713 8.09911 9.33488 8.14506 9.31873 8.19742ZM8.91935 13.1249L10.08 9.5118L11.25 13.1249H8.91935Z"
                    fill="#4880FF"
                  />
                  <path
                    d="M15.675 8.01562H15.075C14.93 8.01562 14.8125 8.13315 14.8125 8.27813V15.2981C14.8125 15.4431 14.93 15.5606 15.075 15.5606H15.675C15.82 15.5606 15.9375 15.4431 15.9375 15.2981V8.27813C15.9375 8.13315 15.82 8.01562 15.675 8.01562Z"
                    fill="#4880FF"
                  />
                  <path
                    d="M12.6113 18.375H7.155C6.74922 18.375 6.36006 18.2138 6.07313 17.9269C5.7862 17.6399 5.625 17.2508 5.625 16.845V7.155C5.625 6.74922 5.7862 6.36006 6.07313 6.07313C6.36006 5.7862 6.74922 5.625 7.155 5.625H16.845C17.2508 5.625 17.6399 5.7862 17.9269 6.07313C18.2138 6.36006 18.375 6.74922 18.375 7.155V12C18.3784 12.1023 18.4221 12.1991 18.4966 12.2693C18.571 12.3396 18.6702 12.3775 18.7725 12.375C19.0918 12.374 19.4069 12.4472 19.6931 12.5888C19.7525 12.6192 19.8188 12.6338 19.8855 12.631C19.9522 12.6282 20.0171 12.6082 20.0738 12.5729C20.1304 12.5376 20.177 12.4882 20.2088 12.4295C20.2407 12.3708 20.2568 12.3049 20.2556 12.2381V7.155C20.2556 6.70738 20.1674 6.26415 19.9959 5.85067C19.8244 5.43719 19.5731 5.06157 19.2563 4.74532C18.9396 4.42906 18.5635 4.17836 18.1498 4.00758C17.736 3.83679 17.2926 3.74927 16.845 3.75H7.155C6.25194 3.75 5.38586 4.10874 4.7473 4.74731C4.10874 5.38587 3.75 6.25194 3.75 7.155V16.845C3.75 17.7481 4.10874 18.6141 4.7473 19.2527C5.38586 19.8913 6.25194 20.25 7.155 20.25H13.3988C13.4755 20.2501 13.5505 20.2275 13.6144 20.1849C13.6782 20.1424 13.728 20.0819 13.7575 20.0111C13.787 19.9403 13.7949 19.8623 13.7801 19.787C13.7653 19.7117 13.7285 19.6425 13.6744 19.5881C13.3826 19.3205 13.1463 18.9981 12.9788 18.6394C12.9519 18.563 12.9023 18.4967 12.8366 18.4494C12.7708 18.4022 12.6922 18.3762 12.6113 18.375Z"
                    fill="#4880FF"
                  />
                  <path
                    d="M20.9492 19.0862L22.648 18.4168C22.6829 18.403 22.7129 18.379 22.7341 18.3479C22.7552 18.3168 22.7665 18.28 22.7665 18.2424C22.7665 18.2048 22.7552 18.1681 22.7341 18.137C22.7129 18.1059 22.6829 18.0819 22.648 18.068L20.9492 17.3987C20.6485 17.2798 20.3754 17.1005 20.1467 16.8718C19.9181 16.6431 19.7387 16.37 19.6198 16.0693L18.9505 14.3818C18.9366 14.3469 18.9125 14.317 18.8814 14.2959C18.8503 14.2749 18.8136 14.2636 18.7761 14.2637C18.7385 14.2636 18.7018 14.2749 18.6707 14.2959C18.6397 14.317 18.6156 14.3469 18.6017 14.3818L17.9323 16.0693C17.8137 16.3702 17.6345 16.6435 17.4058 16.8722C17.1771 17.1008 16.9038 17.2801 16.603 17.3987L14.9155 18.068C14.8805 18.0819 14.8505 18.1059 14.8293 18.137C14.8082 18.1681 14.7969 18.2048 14.7969 18.2424C14.7969 18.28 14.8082 18.3168 14.8293 18.3479C14.8505 18.379 14.8805 18.403 14.9155 18.4168L16.603 19.0862C16.9038 19.2048 17.1771 19.384 17.4058 19.6127C17.6345 19.8414 17.8137 20.1147 17.9323 20.4155L18.6017 22.103C18.6153 22.1383 18.6392 22.1686 18.6703 22.19C18.7015 22.2114 18.7383 22.2229 18.7761 22.223C18.8139 22.2229 18.8507 22.2114 18.8818 22.19C18.9129 22.1686 18.9369 22.1383 18.9505 22.103L19.6198 20.4155C19.7387 20.1148 19.9181 19.8417 20.1467 19.613C20.3754 19.3844 20.6485 19.2051 20.9492 19.0862Z"
                    fill="#4880FF"
                  />
                </svg>
                AI Summary
              </Link>
              <Modal
                open={openModalAI}
                onClose={(e) => setOpenAI(false)}
                header
                headerIcon
              >
                <div className="grid grid-cols-12">
                  <div className="col-span-12 mb-30">
                    <ul className="px-6 max-h-[300px] overflow-y-auto">
                      <li className="flex items-center justify-end mb-4">
                        <div className="bg-light-grey-bg p-3 max-w-[80%] rounded">
                          <Paragraph text14 className={`text-dark-grey`}>
                            {airesponce.question == ""
                              ? "Based on the data, please provide brief client information"
                              : airesponce.question}
                          </Paragraph>
                        </div>
                      </li>
                      {airesponce?.answer != "" ? (
                        <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                          {/* {<RenderBullets data={airesponce?.answer} />} */}
                          {renderJsonList(airesponce?.answer)}
                        </li>
                      ) : (
                        <li className="flex items-center justify-start mb-4">
                          <div className="max-w-[80%]">
                            <Paragraph text14 className={`text-dark-grey`}>
                              Check Your Subscription Settings:
                            </Paragraph>
                            <ul>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                Log in to your account on the platform.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                Navigate to the "Billing" or "Subscription"
                                section.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                Look for "Next Payment Date" or similar details.
                              </li>
                            </ul>
                            <Paragraph text14 className={`text-dark-grey`}>
                              Review Your Last Payment Receipt:
                            </Paragraph>
                            <ul>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                If your plan is billed monthly, the next payment
                                is often one month after the last payment date.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                For annual plans, it will likely be a year from
                                the last payment.
                              </li>
                            </ul>
                            <Paragraph text14 className={`text-dark-grey`}>
                              Check Your Subscription Settings:
                            </Paragraph>
                            <ul>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                Log in to your account on the platform.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                Navigate to the "Billing" or "Subscription"
                                section.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                Look for "Next Payment Date" or similar details.
                              </li>
                            </ul>
                            <Paragraph text14 className={`text-dark-grey`}>
                              Review Your Last Payment Receipt:
                            </Paragraph>
                            <ul>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                If your plan is billed monthly, the next payment
                                is often one month after the last payment date.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                For annual plans, it will likely be a year from
                                the last payment.
                              </li>
                            </ul>
                            <Paragraph text14 className={`text-dark-grey`}>
                              Review Your Last Payment Receipt:
                            </Paragraph>
                            <ul>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                If your plan is billed monthly, the next payment
                                is often one month after the last payment date.
                              </li>
                              <li className="text-[14px] leading-[18px] font-normal text-dark-grey ps-4 relative before:content before:absolute before:top-[8px] before:left-[5px] before:w-[3px] before:h-[3px] before:bg-dark-grey before:rounded-full">
                                For annual plans, it will likely be a year from
                                the last payment.
                              </li>
                            </ul>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="bg-light-grey-bg rounded-b-lg p-6">
                  <div className="relative">
                    <input
                      value={aiMessage}
                      onChange={(e) => setaiMessage(e.target.value)}
                      type="text"
                      placeholder="Write here"
                      className="rounded border-light-grey-200 pr-[46px] border pt-[11px] pb-[10px] text-[16px] leading-[21px] font-normal w-full focus:outline-0 placeholder:text-light-grey text-site-black focus-within:outline-none px-4 "
                    />
                    <button
                      className="absolute top-[10px] right-[14px] border-0 p-0 capitalize rounded inline-flex items-center justify-center gap-3 transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-[16px] leading-[21px] font-semibold disabled:cursor-not-allowed"
                      disabled={!aiMessage.trim().length}
                      onClick={sendAiResponce}
                    >
                      <RiSendPlaneFill
                        fontSize={"24px"}
                        className={"text-primary-blue"}
                      />
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <ul className="xl:px-30 px-4 overflow-y-auto xl:h-[calc(100vh-416px)] h-[calc(100vh-404px)]">
              {followupHistory?.map((item) => (
                <li
                  key={item?.id}
                  className="border border-extra-light-blue rounded-lg p-[15px] mb-3"
                >
                  <ul className="flex items-center gap-2 mb-2">
                    <li className="rounded-full bg-light-grey-bg w-[34px] h-[34px] flex items-center justify-center">
                      <Paragraph text16 className={`text-dark-grey`}>
                        {item?.user.charAt(0).toUpperCase()}
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph text14 className={`text-dark-grey`}>
                        {item?.user}
                      </Paragraph>
                      <Paragraph text12 className={`mt-[2px] text-light-grey`}>
                        via {item?.followup_platform}
                      </Paragraph>
                    </li>
                  </ul>
                  <Paragraph text14>{item?.comments}</Paragraph>
                </li>
              ))}
            </ul>
            <div className="bg-light-blue-bg mt-6 py-4 xxl:px-30 px-4">
              <div className="flex items-center flex-wrap xl:flex-nowrap xxl:gap-[6px] xl:gap-1 gap-[6px] mb-2">
                <label
                  htmlFor="sendWhatsapp"
                  className={`flex items-center gap-1 bg-white border border-white has-[:checked]:border-primary-blue rounded py-[3px] px-[5px] cursor-pointer`}
                >
                  <input
                    type="radio"
                    name="sendMessage"
                    id="sendWhatsapp"
                    checked={
                      selectPlateform === Followup_Platform.WhatsApp
                        ? true
                        : false
                    }
                    className="peer sr-only"
                    onChange={() =>
                      setSelectPlateform(Followup_Platform.WhatsApp)
                    }
                  />
                  <BiLogoWhatsapp
                    fontSize={"10px"}
                    className={`text-white w-4 h-4 peer-checked:bg-[#29A71A] bg-light-grey rounded-full p-[1px]`}
                  />
                  <span className="text-[12px] leading-[16px] font-normal peer-checked:text-site-black text-light-grey xl:block hidden">
                    WhatsApp
                  </span>
                </label>
                <label
                  htmlFor="sendEmail"
                  className={`flex items-center gap-1 bg-white border border-white has-[:checked]:border-primary-blue rounded py-[3px] px-[5px] cursor-pointer`}
                >
                  <input
                    type="radio"
                    name="sendMessage"
                    id="sendEmail"
                    checked={
                      selectPlateform === Followup_Platform.Email ? true : false
                    }
                    className="peer sr-only"
                    onChange={() => setSelectPlateform(Followup_Platform.Email)}
                  />
                  <IoIosMail
                    fontSize={"16px"}
                    className={`text-light-grey peer-checked:text-primary-blue`}
                  />
                  <span className="text-[12px] leading-[16px] font-normal peer-checked:text-site-black text-light-grey xl:block hidden">
                    Email
                  </span>
                </label>
                <label
                  htmlFor="sendCall"
                  className={`flex items-center gap-1 bg-white border border-white has-[:checked]:border-primary-blue rounded py-[3px] px-[5px] cursor-pointer`}
                >
                  <input
                    type="radio"
                    name="sendMessage"
                    id="sendCall"
                    checked={
                      selectPlateform === Followup_Platform.call ? true : false
                    }
                    className="peer sr-only"
                    onChange={() => setSelectPlateform(Followup_Platform.call)}
                  />
                  <BiSolidPhoneCall
                    fontSize={"16px"}
                    className={`text-light-grey peer-checked:text-site-black`}
                  />
                  <span className="text-[12px] leading-[16px] font-normal peer-checked:text-site-black text-light-grey xl:block hidden">
                    Calls
                  </span>
                </label>
                <label
                  htmlFor="sendChat"
                  className={`flex items-center gap-1 bg-white border border-white has-[:checked]:border-primary-blue rounded py-[3px] px-[5px] cursor-pointer`}
                >
                  <input
                    type="radio"
                    name="sendMessage"
                    id="sendChat"
                    checked={
                      selectPlateform === Followup_Platform.Other ? true : false
                    }
                    className="peer sr-only"
                    onChange={() => setSelectPlateform(Followup_Platform.Other)}
                  />
                  <AiFillMessage
                    fontSize={"16px"}
                    className={`text-light-grey peer-checked:text-site-black`}
                  />
                  <span className="text-[12px] leading-[16px] font-normal peer-checked:text-site-black text-light-grey xl:block hidden">
                    Other
                  </span>
                </label>
              </div>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Write here"
                  className="rounded border-light-grey-200 pr-[46px] border pt-[11px] pb-[10px] text-[16px] leading-[21px] font-normal w-full focus:outline-0 placeholder:text-light-grey text-site-black focus-within:outline-none px-4"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <button
                  className={`absolute top-[10px] right-[14px] border-0 p-0 capitalize rounded inline-flex items-center justify-center gap-3 transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-[16px] leading-[21px] font-semibold disabled:cursor-not-allowed`}
                  disabled={
                    comment.trim().length === 0 || selectPlateform == ""
                  }
                  onClick={() => sendComment()}
                >
                  <RiSendPlaneFill
                    fontSize={"24px"}
                    className={
                      comment.trim().length === 0 || selectPlateform == ""
                        ? "text-gray-400"
                        : "text-primary-blue"
                    }
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAccount;
